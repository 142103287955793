<template>
  <div class="">
    <trac-loading v-if="isLoading" />
    <main class="ml-12" v-if="stores && transfers">
      <div class="wrapper">
        <div class="flex flex-row justify-between items-end">
          <ul class="flex-col mt-12 mb-8">
            <li><a href="#">Inventory Control</a></li>
            <li class="font-bold mt-4 text-2xl">
              Inventory Transfer: Outgoing
            </li>
          </ul>
          <div class="mb-8">
            <trac-button
              @button-clicked="$router.push({ name: 'CreateTransfer' })"
              class="uppercase"
              >Create Transfer</trac-button
            >
          </div>
        </div>
      </div>
      <div class="bg-white rounded-lg h-full py-2 big-shadow">
        <div class="mt-8 mb-5 mx-8 justify-between flex flex-row">
          <div class="flex flex-row items-center w-3/12">
            <trac-dropdown-exteneded
              @optionSelected="selectOption($event)"
              placement="left"
              :selector="selectedStore.name"
              :options="stores"
              :neededProperty="'name'"
              class="my-6 capitalize"
            >
            </trac-dropdown-exteneded>
          </div>
        </div>
        <div class="mx-8 mb-8 border rounded-lg overflow-auto">
          <table class="w-full table-auto h-full overflow-auto">
            <thead class="bg-blue-100">
              <tr class="">
                <th class="text-left text-xs font-semibold p-4 uppercase">
                  Name
                </th>
                <th class="text-left text-xs font-semibold p-4 uppercase">
                  Source Store
                </th>
                <th class="text-left text-xs font-semibold p-4 uppercase">
                  Destination Store
                </th>
                <th class="text-left text-xs font-semibold p-4 uppercase">
                  Transfer Status
                </th>
                <th class="text-left text-xs font-semibold p-4 uppercase">
                  Ordered By
                </th>
              </tr>
            </thead>
            <tbody v-if="transfers.length > 0">
              <tr
                @click="gotoTransferDetails(transfer, index)"
                :class="index % 2 === 0 ? '' : 'bg-gray-100'"
                v-for="(transfer, index) in transfers"
                :key="index"
                class="hover:bg-gray-200 cursor-pointer"
              >
                <td class="text-sm font-bold  p-4 capitalize">
                  {{ "Transfer Order " + (index + 1) }}
                </td>
                <td class="text-xs p-4 capitalize">
                  {{ transfer.source.store_name }}
                </td>
                <td class="text-xs font-medium p-4 capitalize">
                  {{ transfer.destination.store_name }}
                </td>
                <td
                  class="text-xs font-medium p-4 capitalize"
                  :class="
                    transfer.status === 'Transferred'
                      ? 'text-secondaryGreen'
                      : transfer.status === 'Held'
                      ? 'text-primaryBlue'
                      : transfer.status === 'In Transit'
                      ? 'text-yellow-500'
                      : 'text-secondaryRed'
                  "
                >
                  {{ transfer.status }}
                </td>
                <td class="text-xs font-medium p-4 capitalize">
                  {{ transfer.source.store_name }} -
                  {{ transfer.destination.store_name }}
                </td>
              </tr>
            </tbody>
            <tbody v-else>
              <tr>
                <td colspan="7">
                  <trac-center-data>
                    <div class="h-40 flex items-center text-lg text-gray-600">
                      No transfers done yet.
                    </div>
                  </trac-center-data>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </main>
  </div>
</template>

<script>
import {
  DELETE_LOCAL_DB_DATA,
  SAVE_LOCAL_DB_DATA,
} from "../../../browser-db-config/localStorage";
import { eventBus } from "./../../../main";

export default {
  data() {
    return {
      isLoading: false,
      stores: null,
      selectedStore: null,
      transfers: null,
    };
  },
  async created() {
    this.isLoading = true;
    DELETE_LOCAL_DB_DATA("transfer-details");

    await this.fetchAllStores();
    await this.fetchAllTransfers();
    this.isLoading = false;
  },
  methods: {
    gotoTransferDetails(transfer, index) {
      SAVE_LOCAL_DB_DATA("transfer-details", {
        ...transfer,
        name: "Transfer Order " + (index + 1),
      });
      this.$router.push({ name: "TransferDetails" });
    },
    async selectOption(store) {
      this.isLoading = true;
      this.selectedStore = store;
      await this.fetchAllTransfers();
      this.isLoading = false;
    },
    async fetchAllTransfers() {
      await this.$store.dispatch("FETCH_ALL_TRANSFERS", this.selectedStore);
      const res = this.$store.getters["GET_ALL_TRANSFERS"];

      if (res.status) {
        this.transfers = res.data || [];
        this.transfers = this.transfers.reverse();
      } else {
        // alert(res.message);
        eventBus.$emit('trac-alert', { message: res.message });
      }
    },
    async fetchAllStores() {
      await this.$store.dispatch("FETCH_ALL_STORES");
      const res = this.$store.getters["GET_ALL_STORES"];

      if (res.status) {
        this.stores = res.data || [];
        this.selectedStore = this.stores[0];
      } else {
        // alert(res.message);
        eventBus.$emit('trac-alert', { message: res.message });
      }
    },
  },
};
</script>

<style></style>
